import { StartingPositions } from "./StartingPositions";
import { SuperImposedState } from "./SuperImposedState";
import { SizingMethod } from "./SizingMethod";
import { RunMethod } from "./RunMethod";
import { RenderType } from "./RenderType";
import { Backtracking } from "./Backtracking";
export class WFCConfig {
    constructor() {
        this.maxRetryCount = 10;
        this.maxDepth = 100;
        this.tileScale = 40;
        this.fast = false;
        this.runSpeed = 10;
        this.runLoop = 30;
        this.tilesHeight = 30;
        this.tilesWidth = 30;
        this.useMouse = false;
        this.edgeWrapAround = false;
        this.edgeSocket = "";
        this.tileName = 'Knots';
        this.set = 'all';
        this.startingPosition = StartingPositions.Random;
        this.canvasHeight = 450;
        this.canvasWidth = 450;
        this.sizingMethod = SizingMethod.Fixed;
        this.offsetX = 0;
        this.offsetY = 0;
        this.autoExpandSize = 1;
        this.autoExpand = false;
        this.runMethod = RunMethod.AutoRun;
        this.neighborDistance = 1;
        this.gridSize = 0;
        this.backtracking = Backtracking.None;
    }
}
export class WFCRenderConfig {
    constructor() {
        this.superImposed = SuperImposedState.Layered;
        this.renderType = RenderType.TilesAndSuperImposed;
        this.canvasHeight = 450;
        this.canvasWidth = 450;
        this.sizingMethod = SizingMethod.Fixed;
    }
}
