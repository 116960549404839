import { WFCTiles } from './WFCTiles';
import { WFCRender } from "./WFCRender";
import { WFCTextRender } from "./WFCTextRender";
import { WFCRunner } from './WFCRunner';
window.getWFCRender = function getWFCRender(canvasId) {
    return new WFCRender(canvasId);
};
window.getWFCTextRender = function getWFCTextRender(canvasId) {
    return new WFCTextRender(canvasId);
};
window.getWFC = function getWFC() {
    return new WFCTiles();
};
window.getWFCRunner = function getWFCRunner(config, wfc) {
    return new WFCRunner(config, wfc);
};
