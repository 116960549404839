import { SuperImposedState } from './SuperImposedState';
import { StartingPositions } from './StartingPositions';
import { SizingMethod } from './SizingMethod';
import { WFCConfig } from './WFCConfig';
import { WFCTiles } from './WFCTiles';
import { RunMethod } from './RunMethod';
import { RenderType } from './RenderType';
export class WFCTextRender {
    constructor(canvasId) {
        this.config = new WFCConfig();
        this.wfc = new WFCTiles();
        this.wfcCallback = (event) => {
            if (event.type != 'step' &&
                event.type != "found" &&
                event.type != "stopped" &&
                event.type != "reset") {
                //console.log('event', event.type, event.data);
            }
            if (event.type == 'step') {
                this.draw(event.data.affectedTiles);
            }
            else if (event.type == 'reset') {
            }
            else {
                this.draw();
            }
            return true;
        };
        this.textField = document.getElementById(canvasId);
    }
    getAvailableTiles() {
        return Object.keys(this.wfc.wfcData.tileSets);
    }
    getSuperImposedStates() {
        return SuperImposedState;
    }
    getStartingPositions() {
        return StartingPositions;
    }
    getSizingMethods() {
        return SizingMethod;
    }
    getRunMethods() {
        return RunMethod;
    }
    getRenderTypes() {
        return RenderType;
    }
    getAvailableSets(tileName) {
        var sets = this.wfc.wfcData.tileSets[tileName];
        if (sets == null)
            return null;
        return Object.keys(sets);
    }
    getTileSets() {
        return this.wfc.wfcData.tileSets;
    }
    async init(config, wfc, wfcRunner) {
        this.config = config;
        this.resizeCanvas();
        this.wfc = wfc;
        this.wfcRunner = wfcRunner;
        this.wfcRunner.addCallback(this.wfcCallback);
    }
    resizeCanvas() {
        if (this.config.sizingMethod == SizingMethod.CalcCanvasSize) {
            this.textField.style.height = (this.config.tilesHeight * this.config.tileScale) + "px";
            this.textField.style.width = this.config.tilesWidth * this.config.tileScale + "px";
        }
        else {
            this.textField.style.height = this.config.canvasHeight + "px";
            this.textField.style.width = this.config.canvasWidth + "px";
        }
        //TODO: Move this check to the config
        if (this.config.sizingMethod == SizingMethod.CalcTileSize) {
            this.config.tilesHeight = Math.floor(this.config.canvasHeight / this.config.tileScale);
            this.config.tilesWidth = Math.floor(this.config.canvasWidth / this.config.tileScale);
        }
        else if (this.config.sizingMethod == SizingMethod.CalcTileScale) {
            this.config.tileScale = Math.max(Math.floor(this.config.canvasHeight) / this.config.tilesHeight, Math.floor(this.config.canvasWidth / this.config.tilesWidth));
        }
    }
    expand() {
        this.resizeCanvas();
        this.draw();
    }
    draw(tiles = undefined) {
        this.drawAllTiles();
    }
    drawAllTiles() {
        let result = [];
        for (let columnIndex = -this.config.offsetX; columnIndex < this.config.tilesWidth - this.config.offsetX; columnIndex++) {
            let columIndexPos = columnIndex + this.config.offsetX;
            if (result[columIndexPos] == undefined) {
                let row = [];
                result[columIndexPos] = row;
            }
            let column = this.wfc.tiles[columnIndex];
            for (let rowIndex = -this.config.offsetY; rowIndex < this.config.tilesHeight - this.config.offsetY; rowIndex++) {
                let tile = column[rowIndex];
                if (tile) {
                    if (tile.key != undefined) {
                        let tileStr = tile.key;
                        result[columIndexPos].push(tileStr);
                    }
                }
            }
        }
        let jsonStr = JSON.stringify(result, null, 2);
        this.textField.innerHTML = "<pre>" + jsonStr + "</pre>";
    }
}
