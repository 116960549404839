export var StartingPositions;
(function (StartingPositions) {
    StartingPositions[StartingPositions["Random"] = 0] = "Random";
    StartingPositions[StartingPositions["TopLeft"] = 1] = "TopLeft";
    StartingPositions[StartingPositions["TopCenter"] = 2] = "TopCenter";
    StartingPositions[StartingPositions["TopRight"] = 3] = "TopRight";
    StartingPositions[StartingPositions["CenterLeft"] = 4] = "CenterLeft";
    StartingPositions[StartingPositions["Mid"] = 5] = "Mid";
    StartingPositions[StartingPositions["CenterRight"] = 6] = "CenterRight";
    StartingPositions[StartingPositions["BottomLeft"] = 7] = "BottomLeft";
    StartingPositions[StartingPositions["BottomCenter"] = 8] = "BottomCenter";
    StartingPositions[StartingPositions["BottomRight"] = 9] = "BottomRight";
})(StartingPositions || (StartingPositions = {}));
