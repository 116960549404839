export var RenderType;
(function (RenderType) {
    RenderType[RenderType["None"] = 0] = "None";
    RenderType[RenderType["TilesAndSuperImposed"] = 1] = "TilesAndSuperImposed";
    RenderType[RenderType["TilesOnly"] = 2] = "TilesOnly";
    RenderType[RenderType["SuperImposedOnly"] = 3] = "SuperImposedOnly";
    RenderType[RenderType["ColorOnly"] = 4] = "ColorOnly";
    RenderType[RenderType["PixelBasedColorDominant"] = 5] = "PixelBasedColorDominant";
    RenderType[RenderType["PixelBasedColorAverage"] = 6] = "PixelBasedColorAverage";
})(RenderType || (RenderType = {}));
