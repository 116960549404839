export class PieceObject {
    constructor(key, name, rotation, validNeighbors, edgeblacklist, weight, sockets, minimum = null, maximum = null) {
        this.key = key;
        this.name = name;
        this.rotation = rotation;
        this.validNeighbors = validNeighbors;
        this.edgeblacklist = edgeblacklist;
        this.weight = weight;
        this.sockets = sockets;
        this.minimum = minimum;
        this.maximum = maximum;
    }
}
